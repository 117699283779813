export abstract class GroupedFirebaseFunctionCompatLayer {
  constructor(protected baseUri: string) { }

  abstract getIdToken(): Promise<string>;

  async call<T>(group: string, name: string, data: any = {}) {
    const headers: any = {
      'Content-Type': 'application/json',
    };
    const authToken = await this.getIdToken();
    if (authToken) {
      headers['Authorization'] = `Bearer ${authToken}`;
    }

    const r = await fetch(`${this.baseUri}/${group}/${name}`, {
      method: 'POST',
      body: JSON.stringify(data),
      mode: 'cors',
      headers,
    });

    return this.unwrapResponse(await r.json()) as { data: T; meta?: any; };
  }

  async request<T = unknown>(
    group: string,
    name: string,
    opts: Parameters<typeof fetch>[1]
  ): Promise<T extends AsyncGenerator ? T : T extends Blob ? T : { data: T, meta?: any; }> {
    const headers: any = { ...opts?.headers };
    const authToken = await this.getIdToken();
    if (authToken) {
      headers['Authorization'] = `Bearer ${authToken}`;
    }

    const r = await fetch(`${this.baseUri}/${group}/${name}`, {
      mode: 'cors',
      ...opts,
      headers,
    });

    if (r.ok && r.headers.get('content-type')?.includes('text/event-stream')) {
      return this.handleSSE(r) as any;
    }

    if (r.ok && r.headers.get('content-type')?.includes('json')) {
      return this.unwrapResponse(await r.json()) as { data: T; meta?: any; } as any;
    }

    if (r.ok && r.headers.get('content-type')?.includes('image')) {
      return await r.blob() as any;
    }

    return this.unwrapResponse(await r.json()) as { data: T; meta?: any; } as any;
  }

  unwrapResponse(r: any) {
    if (typeof r !== 'object' || !r) {
      return { data: r };
    }

    if (r.result) {
      return { data: r.result };
    }

    if (r.error && typeof r.error === 'object') {
      Object.setPrototypeOf(r.error, Error.prototype);
      throw r.error;
    }

    const code = r.code;
    if (typeof code !== 'number') {
      return { data: r };
    }

    if (code >= 200 && code < 300) {
      return r;
    }

    Object.setPrototypeOf(r, Error.prototype);
    throw r;
  }

  async *handleSSE(resp: Response) {
    const reader = resp.body!.getReader();
    const decoder = new TextDecoder();
    const chunks = [];
    let upstreamClosed = false;

    while (true) {
      const { done, value } = await reader.read();
      if (done) {
        upstreamClosed = true;
      }

      const chunk = decoder.decode(value);
      chunks.push(chunk);
      const parsed: string[] = chunks.join('').split(/\r?\n\r?\n/);
      if (!upstreamClosed) {
        chunks.length = 0;
        const c = parsed.pop();
        if (c) {
          chunks.push(c);
        }
      }

      for (const ev of parsed) {
        if (!ev) {
          continue;
        }
        const event: any = {};
        const lines = ev.split(/\r?\n/);
        for (const l of lines) {
          const lineComp = l.split(':');
          if (lineComp.length < 2) {
            continue;
          }
          const key = lineComp.shift()!.trim();
          const value = lineComp.join(':');
          const finalValue = value[0] === ' ' ? value.substring(1) : value;
          if (key !== 'data') {
            event[key] = finalValue;
          } else {
            event.data = `${event.data || ''}${event.data !== undefined ? '\n' : ''}${finalValue}`;
          }
        }
        try {
          yield event;
        } catch (err) {
          reader.cancel(err);
          throw err;
        }
      }

      if (upstreamClosed) {
        break;
      }
    }
  }
}
